import {useMutation, useQueryClient} from 'react-query'
import {postRestaurantTable} from 'api/private/restaurants'
import {RestaurantTablesContent} from 'types/Restaurant'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useCreateRestaurantTable = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {defaultServiceProviderId} = useContext(ServiceProviderContext)

    const {mutateAsync: AddTable} = useMutation(
        (payload: RestaurantTablesContent) => postRestaurantTable(defaultServiceProviderId, payload),
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['restaurants'])
                toast.success(t('table_created_success'), {
                    position: toast.POSITION.TOP_RIGHT
                })
                return data
            },
            onError: async (error: any) => {
                toast.error(t('table_created_error'), {
                    position: toast.POSITION.TOP_RIGHT
                })
                throw error
            }
        }
    )

    return {AddTable}
}
