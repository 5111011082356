import * as React from 'react';
import {useImperativeHandle, useState} from 'react';
import {MemberType} from 'types/Members';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Checkbox, FormControlLabel, Tab, Tabs} from '@mui/material';
import {useUpdateMemberConsentDetails} from 'hooks/members/useUpdateMemberConsentDetails';
import {useMember} from "../../hooks/members/useMember";
import {LegalViewer} from "../LegalViewer";

interface Props {
    member?: MemberType;
    open: boolean;
    setOpen: (open: boolean) => void;
    onValidation: (isValid: boolean) => void;
    formRef: React.RefObject<{ submitForm: () => Promise<void> }>;
}

export const MemberConsentStep: React.FC<Props> = ({onValidation, formRef}) => {
    const {t, i18n} = useTranslation();
    const {member} = useMember();
    const [legal, setLegal] = useState(false);
    const {EditMemberConsentDetails} = useUpdateMemberConsentDetails();
    const [selectedTab, setSelectedTab] = useState(0);

    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleClose = async () => {
        if (legal && member?.id) {
            EditMemberConsentDetails({id: member.id});
        }
    };

    const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegal(event.target.checked);
        onValidation(event.target.checked);
    };

    useImperativeHandle(formRef, () => ({
        submitForm: handleClose
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
            }}
            my={2}
        >
            <Box>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="legal tabs"
                >
                    <Tab label="Data privacy"/>
                    <Tab label="Terms and Conditions"/>
                </Tabs>
                <Box p={3}>
                    {selectedTab === 0 && (
                        <LegalViewer
                            documentUrl="https://skiper-legal.s3.eu-central-1.amazonaws.com/data_privacy"
                            stepper={true}
                        />
                    )}
                    {selectedTab === 1 && (
                        <LegalViewer
                            documentUrl="https://skiper-legal.s3.eu-central-1.amazonaws.com/terms_and_conditions"
                            stepper={true}
                        />
                    )}
                </Box>
            </Box>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={onCheckBoxChange}
                        color="primary"
                    />
                }
                label={
                    <>
                        <span>{t('agree_on_legal_terms_and_conditions_1')}</span>
                        <Link
                            target="_blank"
                            to={`/data-privacy?lang=${getCurrentLng()}`}
                        >
                            {t('agree_on_legal_terms_and_conditions_2')}
                        </Link>
                        <span>{t('agree_on_legal_terms_and_conditions_3')}</span>
                        <Link
                            target="_blank"
                            to={`/terms-and-conditions-service-provider?lang=${getCurrentLng()}`}
                        >
                            {t('agree_on_legal_terms_and_conditions_4')}
                        </Link>
                    </>
                }
            />
        </Box>
    );
};