import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useForm} from "react-hook-form";
import {ServiceProviderType} from "../../types/ServiceProvider";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {serviceProviderAddFormSchema} from "../../config/YupSchemaValidations";
import {useState} from "react";
import {getUserId} from "../../config/auth";
import {useCreateServiceProvider} from "../../hooks/useCreateServiceProvider";
import {useTranslation} from "react-i18next";
import {DEFAULT_SPP_END_TIME, DEFAULT_SPP_START_TIME} from "../../config/const";
import {Alert, Grid, Typography} from "@mui/material";
import {TextFieldController} from "../form/TextFieldController";
import {PhoneNumberController} from "../form/PhoneNumberController";
import {AutocompleteController} from "../form/AutocompleteController";
import {averageTimePerCustomerOptions, serviceProviderCountry} from "../../config/Options";
import CheckboxController from "../form/CheckboxController";
import {useUpdateServiceProvider} from "../../hooks/useUpdateServiceProvider";

interface ServiceProviderStepProps {
    onValidation: (isValid: boolean) => void;
    formRef: React.RefObject<{ submitForm: () => Promise<void> }>;
}

export const ServiceProviderStep = forwardRef<{ submitForm: () => Promise<void> }, ServiceProviderStepProps>(
    ({onValidation, formRef}, ref) => {
        const [restaurantInfo, setRestaurantInfo] = useState<ServiceProviderType | null>(null);
        const userId = getUserId();
        const {AddServiceProvider} = useCreateServiceProvider();
        const {t} = useTranslation();

        const {handleSubmit, trigger, reset, control, formState: {isValid, errors}} = useForm<ServiceProviderType>({
            resolver: yupResolver(serviceProviderAddFormSchema()),
            mode: 'onBlur',
            defaultValues: restaurantInfo || undefined,
        });


        useEffect(() => {
            onValidation(isValid);
        }, [trigger, handleSubmit, isValid]);

        const submitHandler = (values: ServiceProviderType) => {
            const completeRestaurantInfo = {
                ...values,
                logoUrl: '',
                imageUrl: '',
                pricingRange: 0,
                ownerId: userId,
                startTime: DEFAULT_SPP_START_TIME,
                endTime: DEFAULT_SPP_END_TIME,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };
            AddServiceProvider(completeRestaurantInfo);
            setRestaurantInfo(completeRestaurantInfo);
        };

        useImperativeHandle(formRef, () => ({
            submitForm: () => handleSubmit(submitHandler)()
        }));

        return (
            <Grid container my={2}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <Grid container xs={12} direction={'row'} spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant={'h6'}>{t('add_new_service_provider')}</Typography>
                            <TextFieldController control={control} name={'name'} label={'service_provider_name'}/>
                            <TextFieldController control={control} name={'registrationNumber'}
                                                 label={'registration_number_optional'}/>
                            <PhoneNumberController control={control} name={'phoneNumber'} label={'phone_number'}/>
                            <TextFieldController control={control} name={'websiteUrl'} label={'website_optional'}/>
                            <AutocompleteController control={control} name={'averageTimePerCustomer'}
                                                    label={'average_time_per_booking_in_minutes'}
                                                    options={averageTimePerCustomerOptions}/>
                            <TextFieldController control={control} multiline={true} name={'description'}
                                                 label={'service_provider_description'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'h6'}>{t('address')}</Typography>
                            <AutocompleteController control={control} name={'address.country'} label={'country'}
                                                    options={serviceProviderCountry}/>
                            <TextFieldController control={control} name={'address.state'} label={'state_optional'}/>
                            <TextFieldController control={control} name={'address.city'} label={'city'}/>
                            <TextFieldController control={control} name={'address.streetName'} label={'street_name'}/>
                            <TextFieldController control={control} name={'address.streetNumber'}
                                                 label={'street_number'}/>
                            <TextFieldController control={control} name={'address.postalCode'} label={'postal_code'}/>
                            <Alert severity={'info'}>{t('example')}: email@example.com;email2@example.com</Alert>
                            <CheckboxController control={control} name={'bookingEmailsEnabled'}
                                                label={'booking_notification_emails_enabled'}/>
                            <TextFieldController control={control} name={'bookingNotificationEmail'}
                                                 label={'email_placeholder'}/>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );
    })
ServiceProviderStep.displayName = 'ServiceProviderStep';

