import {Alert, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {CustomTimePicker} from "../../CustomTimePicker";
import {defaultTimeOptions} from "../../../config/Options";
import React, {useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {useOpeningHours} from "../../../hooks/useOpeningHours";
import {OpeningHoursType} from "../../../types/ServiceProvider";
import {useUpdateOpeningHours} from "../../../hooks/useUpdateOpenningHours";
import {useServiceProvider} from "../../../hooks/useServiceProvider";
import {openingHoursFormSchema} from "../../../config/YupSchemaValidations";
import i18n from "i18next";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    onValidation?: (isValid: boolean) => void;
}


export const OpeningHours = (props: TabPanelProps,) => {
    const {children, value, index, onValidation, ...other} = props;
    const {t} = useTranslation()
    const {serviceProvider} = useServiceProvider()

    const {OpeningHours} = useOpeningHours(serviceProvider?.id)
    const {UpdateOpeningHours} = useUpdateOpeningHours()
    const schema = useMemo(() => openingHoursFormSchema(), [i18n.language]);

    const {handleSubmit, control, reset} = useForm({
        mode: 'all',
        defaultValues: OpeningHours,
        resolver: yupResolver(schema)
    })

    const submitHandler = (values: OpeningHoursType) => {
        UpdateOpeningHours(values)
        if (onValidation) {
            onValidation(true)
        }
    }

    useEffect(() => {
        if (OpeningHours) {
            reset(OpeningHours)
        }
    }, [OpeningHours])

    return <>
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`opening-hours-tab-panel`}
            aria-labelledby={`opening-hours-tab-panel`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography variant={'h5'} textAlign={"center"} mt={3}
                                mb={1}>{t("opening_hours_header")}</Typography>
                    <Alert sx={{mb: 3}} severity={'info'}>{t('opening_hours_subtitle')}</Alert>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        {OpeningHours && <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}>{t('opening_hours_day_of_week')}</TableCell>
                                    <TableCell align={'center'}>{t('opening_hours_start_time')}</TableCell>
                                    <TableCell align={'center'}>{t('opening_hours_end_time')}</TableCell>
                                    <TableCell align={'center'}>{t('opening_hours_break_start_time')}</TableCell>
                                    <TableCell align={'center'}>{t('opening_hours_break_end_time')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {OpeningHours?.days.map((day, index) => {
                                    return (
                                        <TableRow key={day.dayOfWeek}>
                                            <TableCell>
                                                {t(`${day.dayOfWeek}`)}
                                            </TableCell>

                                            <TableCell>
                                                <CustomTimePicker control={control} name={`days.${index}.startTime`}
                                                                  label={'time'} size={'small'}
                                                                  options={defaultTimeOptions || ''}
                                                                  defaultValue={day.startTime}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <CustomTimePicker control={control} name={`days.${index}.endTime`}
                                                                  label={'time'} size={'small'}
                                                                  defaultValue={day.endTime || ''}
                                                                  options={defaultTimeOptions}/>
                                            </TableCell>
                                            <TableCell>
                                                <CustomTimePicker control={control}
                                                                  name={`days.${index}.breakStartTime`} label={'time'}
                                                                  size={'small'}
                                                                  defaultValue={day.breakStartTime || ''}
                                                                  options={defaultTimeOptions}/>
                                            </TableCell>
                                            <TableCell>
                                                <CustomTimePicker control={control} name={`days.${index}.breakEndTime`}
                                                                  label={'time'} size={'small'}
                                                                  defaultValue={day.breakEndTime || ''}
                                                                  options={defaultTimeOptions}/>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>}
                        <Button variant={'contained'} sx={{mt: 2, mb: 2}} type={'submit'}>{t('submit')}</Button>
                    </form>
                </Box>
            )}
        </div>
    </>
}