import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next'

type props = {
    documentUrl: string
    stepper?: boolean
}

export const LegalViewer = ({documentUrl, stepper = false}: props) => {
    const {t, i18n} = useTranslation()

    const englishName = documentUrl + ".html"
    const germanName = documentUrl + "_de.html"

    useEffect(() => {
        if (i18n.language) {
            setPdfUrl(i18n.language == 'en' ? englishName : germanName)
        }
    }, [i18n.language])


    const [pdfUrl, setPdfUrl] = useState<string>()

    return (

        <>
            {stepper ? (
                <iframe src={pdfUrl} style={{minHeight: "40vh"}} width="100%"/>
            ) : (
                <iframe src={pdfUrl} style={{minHeight: "1000px", marginTop: 50}} frameBorder={0} width="100%" />)
            }

        </>
    )
}
