import React from 'react';
import {Box, Typography, Zoom} from '@mui/material';
import {CheckCircle} from "@mui/icons-material";
import {green} from '@mui/material/colors';
import {useTranslation} from "react-i18next";

interface SetupCompleteProps {
    show: boolean;
}

const SetupComplete: React.FC<SetupCompleteProps> = ({show}) => {
    const {t, i18n} = useTranslation()

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                position: 'relative',
                backgroundColor: '#fff'
            }}
        >
            {show && (
                <>
                    <Zoom
                        in={show}
                        style={{
                            transitionDelay: show ? '500ms' : '0ms',
                        }}
                    >
                        <CheckCircle
                            sx={{
                                color: green[500],
                                fontSize: 80,
                                mb: 2
                            }}
                        />
                    </Zoom>
                    <Zoom
                        in={show}
                        style={{
                            transitionDelay: show ? '800ms' : '0ms',
                        }}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{
                                color: green[700],
                                fontWeight: 'medium',
                                textAlign: 'center'
                            }}
                        >
                            {t('setup_complete')}
                        </Typography>
                    </Zoom>
                </>
            )}
        </Box>
    );
};

export default SetupComplete;