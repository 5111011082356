import React, {useContext, useEffect, useState} from "react";
import {Alert, Button, Grid} from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
    GridSlotProps,
    GridRowsProp,
    GridPaginationModel,
    GridActionsCellItem,
    GridDeleteIcon,
    GridClearIcon, GridRowId
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';
import {useRestaurantTables} from 'hooks/restaurant/useRestaurantTables';
import RestaurantTablesContext from 'context/restaurantTables/RestaurantTablesContext';
import {
    SELECT_PAGE_FILTER_TABLES,
    SELECT_PAGE_SIZE_FILTER_TABLES
} from 'context/restaurantTables/RestaurantTablesReducer';
import {useCreateRestaurantTable} from "../../hooks/restaurant/useCreateRestaurantTable";
import {useUpdateRestaurantTable} from "../../hooks/restaurant/useUpdateRestaurantTable";
import {Snackbar} from "@mui/base";
import {Check, Edit} from "@mui/icons-material";
import {useDeactivateRestaurantTable} from "../../hooks/restaurant/useDeactivateRestaurantTable";


function EditToolbar(props: GridSlotProps['toolbar']) {
    const {t} = useTranslation();
    const {setRows, setRowModesModel} = props;

    const handleClick = () => {
        const id = Date.now();
        setRows((oldRows) => [
            ...oldRows,
            {
                id,
                number: '',
                seats: '',
                minGuests: '',
                type: '',
                fillable: false,
                vip: false,
                status: '',
                isNew: true,
            },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'number'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Button sx={{color: "#7698cd"}} startIcon={<AddIcon/>} onClick={handleClick}>
                {t('add_table')}
            </Button>
        </GridToolbarContainer>
    );
}

interface TableConfigStepProps {
    onValidation: (isValid: boolean) => void;
}

export const TableConfigStep: React.FC<TableConfigStepProps> = ({onValidation}) => {
    const {t} = useTranslation();
    const {AddTable} = useCreateRestaurantTable();
    const {EditTable} = useUpdateRestaurantTable();
    const {restaurantTablesFilter, dispatch} = useContext(RestaurantTablesContext);
    const {restaurantTables,} = useRestaurantTables(restaurantTablesFilter);
    const {deactivateTables} = useDeactivateRestaurantTable()

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: restaurantTablesFilter.page || 0,
        pageSize: restaurantTablesFilter.perPage || 10,
    });

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (restaurantTables?.content) {
            setRows(restaurantTables.content);
        }
    }, [restaurantTables]);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        dispatch({type: SELECT_PAGE_FILTER_TABLES, payload: newModel.page});
        dispatch({type: SELECT_PAGE_SIZE_FILTER_TABLES, payload: newModel.pageSize});
    };

    const processRowUpdate = async (newRow: any) => {
        try {
            if (newRow.isNew) {
                const {id, isNew, ...tableData} = newRow;
                const createdTable = await AddTable(tableData);

                if (createdTable) {
                    onValidation(true);
                    return createdTable;
                }
            } else {
                const updatedTable = EditTable(newRow);
                onValidation(true);
                return updatedTable;
            }
        } catch (error: any) {
            if (newRow.isNew) {
                setRows(prevRows => prevRows.filter(row => row.id !== newRow.id));
                setRowModesModel(prevModel => {
                    const {[newRow.id]: removed, ...rest} = prevModel;
                    return rest;
                });
            }
            setError(t('table_created_error'));
            throw error;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        deactivateTables(+id)
        setRows(rows?.filter((row: any) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows?.find((row: any) => row.id === id);
        // eslint-disable-next-line
        if (editedRow!.isNew) {
            setRows(rows?.filter((row: any) => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            field: 'number',
            type: 'number',
            headerName: t('table'),
            flex: 1,
            editable: true
        },
        {
            field: 'seats',
            type: 'number',
            headerName: t('seats'),
            flex: 1,
            editable: true,
            valueSetter: (value, row) => {
                const seats = Number(value);
                const minGuests = Math.ceil(seats / 2);
                return {...row, seats, minGuests};
            },
        },
        {
            field: 'minGuests',
            type: 'number',
            headerName: t('min_guests'),
            flex: 1,
            editable: true,
            preProcessEditCellProps: (params) => {
                const seats = params.otherFieldsProps?.seats.value;
                const minGuests = params.props.value;

                if (minGuests > seats) {
                    setError(t('min_guests_validation_error'));
                    onValidation(false);
                    return {...params.props, error: true};
                } else {
                    setError(null);
                    return {...params.props, error: false};
                }
            },
            valueGetter: (value, row) => Math.ceil(row.seats / 2),
        },
        {
            field: 'type',
            type: 'singleSelect',
            valueOptions: ['INDOOR', 'OUTDOOR'],
            headerName: t('type'),
            flex: 1,
            editable: true
        },
        {
            field: 'fillable',
            headerName: t('fillable'),
            type: 'boolean',
            flex: 1,
            editable: true
        },
        {
            field: 'vip',
            headerName: t('vip'),
            type: 'boolean',
            flex: 1,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Check/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<GridClearIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key="delete"
                        icon={<GridDeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        }
    ];

    return (
        <>
            <Grid container height={400}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    slots={{toolbar: EditToolbar}}
                    slotProps={{
                        toolbar: {setRows, setRowModesModel},
                    }}
                    onProcessRowUpdateError={error => console.error(error)}
                    paginationMode="server"
                    rowCount={restaurantTables?.totalElements || 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                />
            </Grid>
            {error && (
                <Snackbar
                    open={!!error}
                    autoHideDuration={2000}
                    onClose={() => setError(null)}
                >
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};