import React, {useContext, useEffect, useState} from "react"
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import {Alert, Button, Menu, MenuItem, Select, Theme, Tooltip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useServiceProviders} from 'hooks/useServiceProviders'
import {AddServiceProviderDrawer} from 'components/drawers/AddServiceProviderDrawer'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {ProfileMenu} from 'components/ProfileMenu'
import {useTranslation} from 'react-i18next'
import {useMember} from 'hooks/members/useMember'
import {isMemberOwner} from 'config/auth'
import {useUpdateMemberDetails} from 'hooks/members/useUpdateMemberDetails'
import {MemberConsentModal} from 'components/MemberConsentModal'
import {useDateDiff} from 'hooks/helpers/useDateDiff'
import {useNavigate} from 'react-router-dom'
import moment from "moment/moment";
import 'moment/locale/de'
import 'moment/locale/en-gb'

export const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        display: "flex",
        justifyContent: 'space-between',
    },
    toolbarTitle: {
        width: '200px',
        flexGrow: 1
    },
    logoLg: {
        height: 64,
        position: 'relative',
        left: '-20px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    logoSm: {
        height: 64,
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    languageSwitch: {
        height: '38px'
    }
}))


export const TopBar = () => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openProvider = Boolean(anchorEl)
    const [openServiceProvider, setOpenServiceProvider] = useState(false)
    const {defaultProvider} = useContext(ServiceProviderContext)
    const {serviceProviders} = useServiceProviders()
    const {member} = useMember()
    const {dataDiff} = useDateDiff(member?.planExpiryDate)
    const [openConsentDialog, setOpenConsentDialog] = useState<boolean>(false)
    const navigate = useNavigate()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const {EditMemberDetails} = useUpdateMemberDetails()

    const {t, i18n} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const changeLanguageHandler = (lang: string) => {
        EditMemberDetails({languageCode: lang.toUpperCase(), id: member?.id})
        moment.updateLocale(i18n.language, {week: {dow: 1}})
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        if (member?.languageCode) {
            i18n.changeLanguage(member?.languageCode.toLowerCase())
            moment.updateLocale(member?.languageCode.toLowerCase(), {week: {dow: 1}})
        }
        if (member) {
            setOpenConsentDialog(member.consentGivenAt == undefined)
        }
    }, [member])

    const handleServiceProviderChange = (id: number) => {

        EditMemberDetails({defaultServiceProviderId: id, id: member?.id})
        setAnchorEl(null)
    }

    const handleOpen = () => {
        setOpenServiceProvider(!openServiceProvider)
        setAnchorEl(null)
    }

    return (
        <>
            <AppBar position="static" color={'secondary'} elevation={0}>

                <Toolbar className={classes.toolbar}>
                    {defaultProvider &&
                        <Box sx={{flexGrow: 0}}>
                            <Button
                                id="demo-positioned-button"
                                aria-controls={openProvider ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openProvider ? 'true' : undefined}
                                color={'info'}
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon/>}
                            >
                                {defaultProvider ? <> {defaultProvider.name} - {defaultProvider.address.streetName} {defaultProvider.address.streetNumber} </> : t('select_service_provider')}
                            </Button>

                            <Menu anchorEl={anchorEl} open={openProvider} onClose={() => setAnchorEl(null)}>
                                {serviceProviders.map((item: any) => {
                                        return (
                                            <MenuItem key={item.id}
                                                      onClick={() => handleServiceProviderChange(item.id)}>{item.name} - {item.address.streetName} {item.address.streetNumber}</MenuItem>
                                        )
                                    }
                                )}
                                {isMemberOwner() &&
                                    <MenuItem>
                                        <Button variant={'contained'} sx={{marginRight: 10}} fullWidth
                                                onClick={handleOpen}>{t('add_service_provider')}</Button>
                                    </MenuItem>
                                }
                            </Menu>

                        </Box>

                    }
                    {!defaultProvider && !dataDiff.isExpired &&
                        <Box sx={{flexGrow: 0}}>
                            {isMemberOwner() &&

                                <Button variant={'contained'} className={'add-service-provider'} onClick={() => {
                                    setOpenServiceProvider(!openServiceProvider)
                                    setAnchorEl(null)
                                }}>
                                    {t('add_service_provider')}
                                </Button>
                            }
                        </Box>}
                    {defaultProvider?.serviceProviderStatus === 'CLOSED' &&
                        <Box>
                            <Box>
                                <Tooltip title={`${t('closed_service_provider_tooltip')}`}>
                                    <Alert onClick={() => navigate("/settings/availability")} severity='error'
                                           sx={{
                                               textAlign: 'center',
                                               borderRadius: 10,
                                               padding: '0 10px ',
                                               cursor: 'pointer'
                                           }}>
                                        {t('closed')}
                                    </Alert>
                                </Tooltip>
                            </Box>
                        </Box>
                    }

                    <Box sx={{flexGrow: 1, marginLeft: 2}}>
                        {member?.planExpiryDate ?
                            <Box sx={{width: "max-content"}}>
                                <Alert severity={dataDiff.severity}
                                       sx={{textAlign: 'center', borderRadius: 10, padding: '0 10px '}}>
                                    {dataDiff.trialText}
                                </Alert>
                            </Box> :
                            <Box sx={{width: 150}}>
                                <Button variant={'outlined'} onClick={() => navigate('billing')}>
                                    Skiper Pro
                                </Button>
                            </Box>
                        }
                    </Box>

                    <Typography>
                        {t('welcome')}, {member?.firstName} {member?.lastName}
                    </Typography>

                    <ProfileMenu member={member}/>

                    <Box>
                        <Select value={getCurrentLng().toLowerCase()} className={classes.languageSwitch}
                                onChange={(e) => changeLanguageHandler(e.target.value.toLowerCase())}>
                            <MenuItem value={"en"}>EN</MenuItem>
                            <MenuItem value={"de"}>DE</MenuItem>
                            <MenuItem value={"ka"}>GE</MenuItem>
                        </Select>
                    </Box>

                </Toolbar>
            </AppBar>
            {/*<MemberConsentModal member={member} open={openConsentDialog} setOpen={setOpenConsentDialog}/>*/}
            <AddServiceProviderDrawer openServiceProvider={openServiceProvider}
                                      setOpenServiceProvider={setOpenServiceProvider}/>
        </>
    )
}