import {useMutation, useQueryClient} from 'react-query'
import {postMember} from 'api/private/members'
import {CreateMemberType} from 'types/Members'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

export const useCreateMember = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {mutate: AddMember} = useMutation((payload: CreateMemberType) => (postMember(payload)),
        {
            onSuccess: async () => {
                await queryClient.resetQueries(['members'])
                toast.success(`${t('member_created_success')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            },
            onError: async () => {
                toast.error(`${t('member_created_error')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })
    return {AddMember}
}