import React, {useRef, useState, useEffect} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Container,
    Box, Grid, Typography, Paper, Select, MenuItem, StepIconProps,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ServiceProviderStep} from "./stepperSteps/ServiceProviderStep";
import {TableConfigStep} from "./stepperSteps/TableConfigStep";
import logo from "../logo.svg";
import SetupComplete from "./stepperSteps/SetupComplete";
import {MemberConsentStep} from "./stepperSteps/MemberConsentStep";
import moment from "moment";
import {useMember} from "../hooks/members/useMember";
import {useStyles} from "../layouts/portal/TopBar";
import {useUpdateMemberDetails} from "../hooks/members/useUpdateMemberDetails";
import {ProfileMenu} from "./ProfileMenu";
import {styled} from "@mui/material/styles";
import {Check} from "@mui/icons-material";
import {OpeningHoursStep} from "./stepperSteps/OpeningHoursStep";

interface RestaurantStepProps {
    onFinishSetup: () => void,
}

interface StepSubmitRef {
    submitForm: () => Promise<void>;
}

interface StepperState {
    activeStep: number;
    stepValidation: {
        memberConsent: boolean;
        serviceProvider: boolean;
        tableConfig: boolean;
        openingHours: boolean;
    };
}

const STORAGE_KEY = 'restaurantSetupProgress';

const RestaurantSetupStepper: React.FC<RestaurantStepProps> = ({onFinishSetup}) => {
    const classes = useStyles()
    const {t, i18n} = useTranslation();
    const {member} = useMember()
    const {EditMemberDetails} = useUpdateMemberDetails()
    const formRef = useRef<StepSubmitRef>();
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean, completed?: boolean } }>(
        ({theme, ownerState}) => ({
            color: theme.palette.text.disabled,
            display: 'flex',
            justifyContent: 'center',
            height: 22,
            width: 22,
            alignItems: 'center',
            backgroundColor: "#999",
            borderRadius: '100%',
            padding: 2,
            ...(ownerState.active && {
                color: theme.palette.secondary.main,
                backgroundColor: "#7698cd",
            }),
            ...(ownerState.completed && {
                color: theme.palette.success.main,
                backgroundColor: "#7698cd",
            }),
        }),
    );

    function CustomStepIcon(props: StepIconProps) {
        const {active, completed, icon} = props;

        return (
            <CustomStepIconRoot ownerState={{active, completed}}>
                {completed ? <Check color={'secondary'}
                                    style={{padding: 2, borderRadius: '100%'}}/> : icon}
            </CustomStepIconRoot>
        );
    }

    const changeLanguageHandler = (lang: string) => {
        EditMemberDetails({languageCode: lang.toUpperCase(), id: member?.id})
        moment.updateLocale(i18n.language, {week: {dow: 1}})
        i18n.changeLanguage(lang)
    }

    const [showCompletion, setShowCompletion] = useState(false);

    const [state, setState] = useState<StepperState>(() => {
        const savedState = localStorage.getItem(STORAGE_KEY);
        return savedState ? JSON.parse(savedState) : {
            activeStep: 0,
            stepValidation: {
                memberConsent: false,
                serviceProvider: false,
                tableConfig: false,
                openingHours: false
            }
        };
    });

    const {activeStep, stepValidation} = state;

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [state, Object.values(stepValidation)]);

    const handleFinish = async () => {
        setError(null);
        setIsSubmitting(true);

        try {
            if (formRef.current?.submitForm) {
                await formRef.current.submitForm();
                setShowCompletion(true);
                setTimeout(() => {
                    localStorage.removeItem(STORAGE_KEY);
                    onFinishSetup();
                }, 2000);
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to save settings');
        } finally {
            setIsSubmitting(false);
        }
    };


    const handleNext = async () => {
        const isCurrentStepValid = Object.values(stepValidation)[activeStep];

        if (isCurrentStepValid) {
            try {
                if (formRef.current?.submitForm) {
                    await formRef.current.submitForm();
                }
                setState(prevState => ({
                    ...prevState,
                    activeStep: prevState.activeStep + 1
                }));
            } catch (error) {
                console.error('Form submission failed:', error);
            }
        }
    };

    const handleStepValidation = (step: keyof typeof stepValidation, isValid: boolean) => {
        setState(prevState => ({
            ...prevState,
            stepValidation: {
                ...prevState.stepValidation,
                [step]: isValid
            }
        }));
    };

    const steps = [
        {
            label: t('terms_and_conditions'),
            component: MemberConsentStep,
            validationKey: 'memberConsent'
        },
        {
            label: t('add_service_provider'),
            component: ServiceProviderStep,
            validationKey: 'serviceProvider'
        },
        {
            label: t('configure_tables'),
            component: TableConfigStep,
            validationKey: 'tableConfig'
        },
        {
            label: t('opening_hours'),
            component: OpeningHoursStep,
            validationKey: 'openingHours'
        }
    ];

    const CurrentStepComponent: any = steps[activeStep].component;

    return (
        <Box sx={{maxHeight: '100vh'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingX: '24px'}}>
                <Box component="img" alt="Skiper Portal Logo" src={logo}
                     style={{height: 95, cursor: 'pointer'}}/>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: 2}}>
                    <Typography>
                        {t('welcome')}, {member?.firstName} {member?.lastName}
                    </Typography>
                    <ProfileMenu member={member}/>
                    <Select value={getCurrentLng().toLowerCase()} className={classes.languageSwitch}
                            onChange={(e) => changeLanguageHandler(e.target.value.toLowerCase())}>
                        <MenuItem value={"en"}>EN</MenuItem>
                        <MenuItem value={"de"}>DE</MenuItem>
                        <MenuItem value={"ka"}>GE</MenuItem>
                    </Select>
                </Box>
            </Box>
            <Container style={{width: '100%'}}>
                <Paper sx={{
                    padding: 5,
                    backgroundColor: '#f8f8f8a1',
                    borderRadius: '10px',
                    boxShadow: 3
                }}>
                    <Typography style={{textAlign: 'center', marginBottom: 40,}}
                                variant={"h5"}>{t("welcome_skiper_portal")}</Typography>
                    <Stepper activeStep={activeStep} alternativeLabel sx={{pb: 3}}>
                        {steps.map((step) => (
                            <Step key={step.label}>
                                <StepLabel StepIconComponent={CustomStepIcon}>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{maxHeight: '60vh', overflowY: 'auto', paddingX: 2}}>
                        <CurrentStepComponent
                            onValidation={(isValid: boolean) =>
                                handleStepValidation(steps[activeStep].validationKey as keyof typeof stepValidation, isValid)
                            }
                            formRef={formRef}
                        />
                    </Box>
                    <Grid container sx={{justifyContent: 'space-between', paddingTop: 2}}>
                        <Typography variant='subtitle2' align='center'></Typography>
                        {
                            activeStep < steps.length - 1 ?
                                <Button
                                    variant="onboardingButton"
                                    type="submit"
                                    onClick={handleNext}
                                    disabled={!Object.values(stepValidation)[activeStep]}
                                >
                                    {t('next')}
                                </Button>
                                : <Button
                                    variant='onboardingButton'
                                    onClick={handleFinish}
                                    disabled={!Object.values(stepValidation)[activeStep]}
                                >
                                    {t('save_and_finish')}
                                </Button>
                        }
                    </Grid>
                </Paper>
                {showCompletion && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 999,
                        backgroundColor: 'white'
                    }}>
                        <SetupComplete show={showCompletion}/>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default RestaurantSetupStepper;